<template>
  <div class="thanks">
    <v-row>
      <v-col cols="11" md="7" class="mx-auto">
        <v-img src="@/assets/tuvoto.png"></v-img>
      </v-col>
    </v-row>
    <v-card class="mx-auto mt-8" flat>
      <v-card-title class="red--text">
        <h2>Gracias por votar con nosotros</h2>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <a @click="exitPreview">
          <h3>Salir de la vista previa</h3>
        </a>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "PreviewThanks",
  methods:{
    exitPreview() {
      const from = this.$route.params.from;
      const [module, step] = from.split("-");
      
      this.$router.push("/"
        + module + "/"
        + this.$route.params.id + "/"
        + step);
    }
  }
};
</script>
