<template>
  <div class="thanks">
    <v-row>
      <v-col cols="11" md="7" class="mx-auto">
        <v-img src="@/assets/tuvoto.png"></v-img>
      </v-col>
    </v-row>
    <v-card class="mx-auto mt-8" flat>
      <v-card-title class="red--text">
        <h2>Gracias por votar con nosotros</h2>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <router-link :to="'/'" style="text-decoration: none; color: inherit;">
          <a>
            <h3>Continuar</h3>
          </a>
        </router-link>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Thanks"
};
</script>
